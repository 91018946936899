define("discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-authorization", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _object, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ActivityPubAuthorization = _object.default.extend();
  ActivityPubAuthorization.reopenClass({
    remove(authId) {
      return (0, _ajax.ajax)(`/ap/auth/destroy/${authId}.json`, {
        type: "DELETE"
      }).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = ActivityPubAuthorization;
});