define("discourse/plugins/discourse-activity-pub/discourse/routes/admin-plugins-activity-pub-actor", ["exports", "@ember/array", "discourse/lib/ajax", "discourse/routes/discourse", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor"], function (_exports, _array, _ajax, _discourse, _activityPubActor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsActivityPubActorRoute extends _discourse.default {
    queryParams = (() => ({
      order: {
        refreshModel: true
      },
      asc: {
        refreshModel: true
      },
      model_type: {
        refreshModel: true
      }
    }))();
    model(params) {
      const searchParams = new URLSearchParams();
      Object.keys(this.queryParams).forEach(param => {
        if (params[param]) {
          searchParams.set(param, params[param]);
        }
      });
      return (0, _ajax.ajax)(`${_activityPubActor.actorAdminPath}?${searchParams.toString()}`);
    }
    setupController(controller, model) {
      controller.setProperties({
        actors: (0, _array.A)((model.actors || []).map(actor => {
          return _activityPubActor.default.create(actor);
        }))
      });
    }
  }
  _exports.default = AdminPluginsActivityPubActorRoute;
});