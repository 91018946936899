define("discourse/plugins/discourse-activity-pub/discourse/routes/preferences-activity-pub", ["exports", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/utilities", "discourse/routes/restricted-user"], function (_exports, _service, _ajax, _ajaxError, _utilities, _restrictedUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PreferencesActivityPubRoute extends _restrictedUser.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    showFooter = true;
    afterModel() {
      if (!this.site.activity_pub_enabled) {
        return this.router.transitionTo(`discovery.${(0, _utilities.defaultHomepage)()}`);
      }
      return (0, _ajax.ajax)("/ap/auth.json").then(result => {
        this.authorizations = result.authorizations;
      }).catch(_ajaxError.popupAjaxError);
    }
    setupController(controller) {
      controller.set("authorizations", this.authorizations);
      controller.showError();
    }
  }
  _exports.default = PreferencesActivityPubRoute;
});